/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.modal-open .ion-page {
    width: 100%;
    height: 100%;
    position: static;
    display: inherit;
    z-index: auto !important;
}

body:has(.camera-opened){
    background:transparent !important;
    --background:transparent !important;
}
ion-modal.custom {
    // --height:auto;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

    h3 {
        text-align: center;
        color: #333;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: normal;
    }
}

ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 80vh;
            // overflow: auto;
            padding: 10px;
        }
    }
}

.error-message {
    font-size: 12px;
    color: #c70000;
    margin-left: 15px;
}

.alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
    white-space: pre-line !important;
}

#cameraPreview{
    display: flex;
    justify-content: center;
    align-items: center;
    --background: transparent;
    height: 100%;
    width: 100%;
    #video{
        width: 100%;
        max-width: 100%;
    }
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}



