@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap');

body{
		font-family: 'Rubik', sans-serif;
		color:#666;


}
ul {
  margin: 0;
  padding-left: 0;
}

.form-control:focus{

	border-color:#ced4da!important;

}

.noscroll{
		overflow:hidden;

	}

	#esqueci-p-acesso .undeline{
		font-size:14px;
	}

	 #nav-icon3 {
  width: 100%;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin-right:20px;
  z-index:999;
}

#nav-icon3.open span{
	 background-color:#001451;
 }

 #nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 18px;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}




#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 6px;
}

#nav-icon3 span:nth-child(4) {
  top: 12px;
}

#nav-icon3.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}


.titulo{
	text-align:center;
	color:#fff;
	font-weight:500;
	font-size:15px;

}


@media screen and (max-width:992px) {

.modal-bottom .modal-dialog {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  max-width: 100%;
  margin: 0;
  min-height: inherit;
  transform: translate(0,100px)!important;


}

#offcanvasRight-assinatura {
	width:100%;
}




.custom .modal-bottom .modal-dialog{

}


.custom.modal.show .modal-dialog {
    transform: none!important;
}
.custom .modal-content {

border-radius: 30px 30px 0px 0px!important;
}



}



.custom .modal-content{
	border-radius:30px;

border:0;
}


.modal-title{
	color:#001451;
	font-weight:normal;
	font-size:18px;
	text-transform:uppercase;
}

.custom .modal-content .btn-close {
	opacity:1;


  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23333'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;

}

#modal-protocolo strong{
	color:#003FB8;
}

 .modal-header{
	border:none!important;
	font-weight:normal;

}

.custom .modal-content h3{
	text-align:center;
	color:#333;
	text-transform:uppercase;
	font-size:18px;
	font-weight:normal;
}


.modal-content h4{
	text-align:center;
	color:#333;
	font-size:16px;
	font-weight:normal;
}


.tel-link{
	color:#001451;
	text-decoration:none;
	font-size:25px;
	font-weight:500;
	    text-align: center;
    display: block;
}

#sim-todos{
	border-top:solid #DDDDDD 1px;
		border-bottom:solid #DDDDDD 1px;
	padding:10px 0;
}

#sim-todos .form-check-label{
	color:#001451;

}

#form-check-list .btn-secondary{
width: 100%;
    border-radius: 5px;
    background: #fff;
    color: #666;
    border-color: #ddd;

}

.group-radio span{
	display:block;
	font-weight:500;
	font-size:14px;
	width:100%;
	margin-bottom:5px;
}



 .btn-secondary{

	    color: #666;
    background-color: #fff;
    border-color: #ddd;
}

.group-radio label{


}


.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
    color: #fff!important;
    background-color: #001451!important;
    border-color: #001451!important;
}



.btn-check:focus+.btn-secondary, .btn-secondary:focus{
	color: #fff;
    background-color: #001451;
    border-color: #001451;
}



#collapse-proprietario{
	display:none;
}


#collapse-proprietario.visible{
	display:block
}

.bt-stroke-blue{
	color: #006CC3;
    background-color: #fff;
    width: 100%;
    border: 1px solid #006CC3;
    text-align: center;
    border-radius: 5px;
	padding: 0.625rem 1.25rem;
	    font-size: 15px;
    font-weight: normal;
	display:block;
	text-decoration:none;
}

.inputfile-1 + label {
    color: #006CC3;
    background-color: #fff;
    width: 100%;
    border: 1px solid #006CC3;
    text-align: center;
    border-radius: 5px;
}

#header-assinatura{
	padding:10px 0;
	border-bottom:1px solid #DDDDDD;
}

#header-assinatura h6{
	color:#001451;
	margin-top:5px;

	font-weight:normal;
}


#bg-instalacao-finalizada{
	background:#006CC3;

}

#content-instalacao-finalizada{
	color:#fff;
	text-align:center;
	font-weight:normal;


}

#content-instalacao-finalizada .table-align{
		position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
}


.offcanvas{
	border:0!important;
}


#dados-perfil .red{
	color:#ED1B24;
	font-weight:bold;
}

#dados-perfil h6{
	color:#001451;
	font-size:18px;
	font-weight:500;
}
#dados-perfil p strong{
	color:#001451;
}


#form-perfil label{
	font-weight:500;
	font-size:14px;
}
#offcanvasRight-assinatura .offcanvas-header{
	border-bottom:1px solid #ddd;
}

#offcanvasRight-assinatura .offcanvas-header h5{
	color:#001451

}


@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}


	#menu-mobile{
		position:absolute;
		background:#eee;
		top:0;
		left:0;
		width:100%;

		z-index:777;
		 height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
      -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
	}





	#menu-mobile{
		display:none;
	}

	#topo-menu img{
		width:164px;

	}

	#topo-menu{
		background-color:#fff;
		border-bottom:1px solid #ddd;
	}


		#menu-mobile.abrir{
		display:block;
	}


	#menu-mobile  .nav{
			width:100%;
			margin-top:50px;
	}

	#menu-mobile  ul {
		width:100%;
		padding:0 20px;

	}

	#menu-mobile  ul li{
		text-align:center;
		list-style:none;
		border-bottom:1px solid #DDDDDD;
		padding:10px 0;

	}

	#menu-mobile  ul li a{
		color:#333333;
		text-decoration:none;
	}


.form-select:focus, .form-control:focus{
	 box-shadow: none!important;
	border-color:#ddd;
}

.form-control, .form-select{
	font-size: 15px!important;
	padding: 0.64rem 0.75rem!important;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-outline-secondary:focus, .accordion-button:focus {

  box-shadow: none!important;

}
#bg-intro{
height: 100%;
width: 100%;
position: absolute;
left: 0;
right: 0;
    background-size: cover;
background-repeat:no-repeat;



}



#logo-intro{
	position:relative;
	z-index:22;
}

#content-intro{
	color:#fff;

	text-align:center;
}


#content-intro h2{
	font-size:50px;
	font-weight:normal;

}

#content-intro h6{
	font-size:14px;
}



.bt{
	text-decoration:none;
	display:block;
}

button.bt-red{
	width:100%;

}

.bt-red, .bt-red:hover{
	color:#fff;
	font-size:14px;
	font-weight:500;
	text-align:center;
	background-color:#ED1B24;
	border-radius:100px;
	padding:10px 0;
	margin:0 auto;
	border:2px solid #ED1B24;
}


.bt-white, .bt-whit:hover{
	color:#006CC3;
	font-size:14px;
	font-weight:500;
	text-align:center;
	background-color:#fff;
	border-radius:100px;
	padding:10px 0;
	max-width:334px;
	margin:0 auto;
	border:2px solid #fff;
}

.bt-stroke{

	font-size:14px;
	border:1px solid #fff;
	border-radius:100px;
	display:inline-block;
	padding:4px 20px;
	border:1px solid #ddd;
	color:#666;
}


.bt-stroke-big, .bt-stroke-big:hover{
	color:#666;
	font-size:14px;
	font-weight:500;
	text-align:center;
	background-color:#fff;
	border-radius:100px;
	padding:10px 0;
	max-width:334px;
	margin:0 auto;
	border:2px solid #ddd;
}

.bt-blue, .bt-blue:hover {

		color:#fff;
	font-size:14px;
	font-weight:500;
	text-align:center;
	background-color:#001451;
	border-radius:100px;
	padding:10px 0;
	margin:0 auto;
	border:2px solid #001451;
}


.undeline{
	text-decoration:undeline;
	color:#666;
}

#voltar-login{
	font-size:16px;
	padding:20px 0;

}

.light-group-form .form-control{
	border-left:0;
}

.light-group-form .input-group-text{
	background:none;
	padding-left:15px;
	padding-right:0px;

}

.eye-slash{

	width:19px;
	height:17px;
	display:block;
	background-image:url('../images/olho-fechado.svg');

}


.light-group-form .input-group-text.olhos {
	padding: 0.375rem 0.75rem;

}

.eye{

	width:19px;
	height:17px;
	display:block;
	background-image:url('../images/olho-aberto.svg');
}
#content-intro .bt-stroke{
	color:#fff;
	border:1px solid #fff;
}

	.table-align{
		width:100%;
		height:100%;
		display:table;
	}


	.cell-align{
		width:100%;
		height:100%;
		vertical-align:middle;
		    display: table-cell;
	}


	#txt-intro{
			position:relative;
	z-index:33;
	}


	#topo{
		background:#001451;
		padding-top:30px;
		padding-bottom:5px;
		width:100%;
		color:#fff;
        min-height: 100px;
	}

	#filtros{
		background:#EEEEEE;


	}

 #filtros .input-group .input-group-text:first-child{
		background:#001451;
		border-color:#001451;
		color:#fff;
		width:80px;
	}


 #filtros .input-group>.form-control,
 #filtros .input-group>.form-select{
	border-left:0;
	border-right:0;
}

 #filtros .input-group .input-group-text:last-child{
	 background-color:#fff;

 }

.rounded-content{
	position: relative;
    padding-bottom: 30px;

}

 .rounded-content:before{

	 	 content:'';
	 height:30px;
	 width:100%;
	 position:absolute;
	 top:-30px;
	 -webkit-border-top-left-radius: 20px;
-webkit-border-top-right-radius: 20px;
-moz-border-radius-topleft: 20px;
-moz-border-radius-topright: 20px;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
 }

  #filtros.rounded-content:before{

background-color:#EEEEEE;
 }

 #listagem-visitas{
	 margin-bottom:85px;
 }
 .item-visita{
	 font-size:13px;
	 border:1px solid #DDDDDD;
	 border-radius:5px;
 }



  .item-visita a{
	  color:#666;
	  text-decoration:none;
  }

 .item-visita h2{
	 font-size:15px;
	 color:#001451;
	 font-weight:500;
 }

 .item-visita p{
	 margin:3px;
 }

.item-visita div[class*='col-']:first-child{
	position:relative;


	 }

   .item-visita .data{
	   height:120px;
   }
  .item-visita .data .dia{
	  color:#006CC3;
	  font-size:20px;

  }

   .item-visita .data .mes{
	  color:#006CC3;
	  font-weight:500;
	  font-size:20px;
	  line-height:15px;
  }

  .item-visita .hora{
	  background-color:#006CC3;
	  color:#fff;
	  padding:5px 0;
	  width:100%;
	  position:absolute;
	  left:0;
	  bottom:0;
	  -webkit-border-bottom-left-radius: 5px;
-moz-border-radius-bottomleft: 5px;
border-bottom-left-radius: 5px;
  }

  .bt-fixed{
	  position:fixed;
	  bottom:18px;
	  right:13px;
	  padding-left:20px;
	  padding-right:20px;
	  box-shadow: 0 0 15px 2px #ED1B24;

	  box-shadow: 0 0 20px 2px rgb(237 27 36 / 0.5);
  }


  #topo-steps{
	  background:#eee;
	   padding-bottom: 10px;
  }


  #topo-steps:before {
    background-color: #EEEEEE;
}

 #nav-steps li{
	 display:inline-block;
	 border:1px solid #001451;
	 width:12px;
	 height:12px;
	 border-radius:100px;
	 margin:0 10px;
 }

 #nav-steps li.active{
	 background:#001451;
 }

 .form-label{
	 font-weight:500;
	 margin-bottom:2px;
	 font-size:14px;

 }

 .js-signature canvas{
	 border-radius:5px;

 }

 .box-photo span{
	 display:block;
 }


 .box-photo  .inputfile + label {
    max-width: 100%;
    font-size: 15px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: inherit;
	padding:15px 5px;
	height:150px;
	position:relative;


  display:block;
    overflow:  inherit;

    border: 1px solid #001451;
	border-radius:5px;

}

  .box-photo p{
	  margin:0;
	  line-height:15px;
	  font-size:13px;
  }


 .box-photo  h6{
	 color: #001451;
	 font-size:15px;
	 margin-bottom:5px;
	 margin-top:10px;

 }


 .check-upload{
	 position:absolute;
	 right:15px;
	top:15px;
 }

.disabled-check .form-check-input:disabled{
	 opacity:1;
 }




.disabled-check label{

	 	 opacity:1!important;
		 color:#000;
 }


 .min-bt-red, .min-bt-red:hover {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background-color: #ED1B24;
    border-radius: 5px;
    padding: 8px 0;
    max-width: 334px;
    margin: 0 auto;
    border: 2px solid #ED1B24;
}


 .min-bt-green, .min-bt-green:hover {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background-color: #3CC797;
    border-radius: 5px;
    padding: 8px 0;
    max-width: 334px;
    margin: 0 auto;
    border: 2px solid #3CC797;
}


 .bt-close-white{
	 position:absolute;
	 top:15px;
	 right:15px;
 }

 .custom-canvas{
border-top-left-radius: 30px;
border-top-right-radius: 30px;


 }


   #white-content{
	  background:#fff;
	   padding-bottom: 10px;
  }


  #white-content:before {
    background-color: #fff;
}




.mail-phone {
  font-size: 23px;
  font-weight: 500;
  color: #333333;
}

#content-cadastro .bt-white{
	border-radius:5px;
	border:solid #ddd 2px;
}

.codigo-validacao .form-control {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 2px solid #ccc;
  font-size: 40px;
  color: #003FB8;
  font-weight: 600;
  background-color: transparent;
  padding: 0;
  width: 180px;
  text-align: center;
  margin: 0 auto;
}

 #form-senha h2{
	 font-size:15px;
	 font-weight:500;
	 color:#001451;
 }


 .condicoes-senha {
  background-color: #eee;
  border:1px solid #ddd;
  padding: 10px 8px;
  border-radius: 5px;
  font-size: 14px;
}


.condicoes-senha ul{
	margin-top:10px;
}



@media screen and (max-width:768px) {

	#bg-intro{
		background-position-x: -439px;

	}

	#txt-intro{
	position:absolute;
	width:100%;
	left:0;
	bottom:60px;
}


}


.custom-file-button {
	input[type="file"] {
		margin-left: -2px !important;

		&::-webkit-file-upload-button {
			display: none;
		}
		&::file-selector-button {
			display: none;
		}
	}

	&:hover {
		label {
			background-color: #dde0e3;
			cursor: pointer;
		}
	}
}

.modal-backdrop {
    /* z-index: 1040 !important; */
}
.modal-container {
    position: relative;
    z-index: 1060; /* ou outro valor alto que funcione para o seu caso */
}

.modal {
    /* position: fixed !important; */
    /* z-index: 9999 !important; */
    /* background-color:transparent!important; */
}

.bt-stroke-green{
	color: #4BB543;
    background-color: #fff;
    width: 100%;
    border: 1px solid #4BB543;
    text-align: center;
    border-radius: 5px;
	padding: 0.625rem 1.25rem;
	    font-size: 15px;
    font-weight: normal;
	display:block;
	text-decoration:none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bt-stroke-green #pen-clip-solid{
    fill: #4BB543;
    margin-right: 5px;
}
